<template>
  <el-card class="iot-dtu iot-search-header public-page-style">
     <input type="file" id="files" ref="files" @change="upload" style="display:none">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="mini"
            clearable
             style="width:180px"
            v-model="getForm.dtuId"
            placeholder="请输入查询的编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="mini"
            clearable
            v-model="getForm.dtuSn"
            style="width:180px"
            placeholder="请输入查询的序列号"
          ></el-input>
        </el-form-item>
    
        <el-form-item prop="networkModeId" >
          <el-select
          size="mini"
            v-model="getForm.networkModeId"
             style="width:180px"
            filterable
            clearable
            placeholder="请选择联网方式"
          >
            <el-option
              v-for="item in networkModeArr"
              :key="item.networkModeId"
              :label="item.networkMode"
              :value="item.networkModeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="hardwareTypeId" >
          <el-select
          size="mini"
            v-model="getForm.hardwareTypeId"
            filterable
            clearable
             style="width:180px"
            placeholder="请选择硬件型号"
            @change="hardChangeList"
          >
            <el-option
              v-for="item in hardwareArr"
              :key="item.hardwareTypeId"
              :label="item.typeName"
              :value="item.hardwareTypeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="firmwareTypeId" >
          <el-select
          size="mini"
            v-model="getForm.firmwareTypeId"
            filterable
            clearable
             style="width:180px"
            placeholder="请选择固件类型"
          >
            <el-option
              v-for="item in firmwareArrList"
              :key="item.firmwareTypeId"
              :label="item.firmwareTypeName"
              :value="item.firmwareTypeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item  >
          <el-select
          size="mini"
            v-model="getForm.dtuState"
            filterable
            style="width:180px"
            placeholder="在线状态"
          >
           <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
           </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="selectData()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          size="mini"
          plain
          class="iot-dtu-create"
          @click="addDtu()"
          >生成序列号</el-button
        >
         <el-button
          type="primary"
          size="mini"
          style="margin-right:15px;"
          class="iot-dtu-create"
          @click="uploadFileVis()"
          >导入序列号</el-button
        >
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      border=""
      class="dtu-tab"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
      max-height="650"
    >
      <el-table-column
        prop="dtuId"
        label="编号"
        width="150"
        sortable
      ></el-table-column>
      <el-table-column
        prop="dtuSn"
        label="序列号"
        width="160"
        sortable
      ></el-table-column>
      <el-table-column
        prop="dtuVersion"
        label="版本"
        width="80"
      ></el-table-column>
      <el-table-column label="在线状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.dtuState == 1">在线</el-tag>
          <el-tag type="info" v-else-if="scope.row.dtuState == 2">离线</el-tag>
          <el-tag type="danger" v-else-if="scope.row.dtuState == 3">告警</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="绑定状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.bindingState == 1"
            >绑定</el-tag
          >
          <el-tag type="info" v-else-if="scope.row.bindingState == 2"
            >未绑定</el-tag
          >
        </template>
      </el-table-column>
      
      <el-table-column
        prop="hardwareTypeName"
        label="硬件类型"
        width="220px"
      ></el-table-column>
      <el-table-column
        prop="firmwareTypeName"
        label="固件类型"
        width="220px"
      ></el-table-column>
       <el-table-column
        prop="networkMode"
        label="联网方式"
        width="220"
      ></el-table-column>
      <el-table-column
        prop="bindingTime"
        label="绑定时间"
        width="160px"
        sortable
      ></el-table-column>
      <el-table-column
        prop="untyingTime"
        label="解绑时间"
        width="160px"
        sortable
      ></el-table-column>
      <!-- <el-table-column
        prop="uploadCycle"
        label="上传周期（秒）"
        width="120"
      ></el-table-column> -->
     
      <el-table-column label="操作" width="220" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="editDtu(scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            @click="upgrade(scope.row.dtuId)"
            >升级</el-button
          >
          <el-button
            size="mini"
            type="success"
            plain
            @click="details(scope.row.dtuSn)"
            >详情</el-button
          >
          
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="getForm.size"
      :total="getForm.total"
      layout="total, sizes, prev, pager, next, jumper"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    ></el-pagination>


      <el-dialog
    width="28%"
      top="8%"
      :title="formTitle"
      :show-close="false"
      v-if="uploadFile"
      :visible.sync="uploadFile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form :model="uploadFileForm" ref="subRef" label-width="120px" :rules="rules">
        <el-form-item label="联网方式" prop="number">
        <el-select
           v-model="uploadFileForm.networkModeId"  
                  clearable
                  filterable-
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in networkList"
                    :key="item.networkModeId"
                    :label="item.networkMode"
                    :value="item.networkModeId"
                  >
                  </el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="上传文件" prop="number" v-if="isCreate == true">
          <el-button
          type="primary"
          size="mini"
          style="margin-right:15px;"
          
          @click="file()"
          >选择文件</el-button
        >
        </el-form-item>
        <el-form-item style="margin-top:-20px">
         {{ FileName }}
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" size="mini" @click="parameterImport">确 定</el-button>
        <el-button size="mini" @click="uploadFile = false">取 消</el-button>
       
      </div>
    </el-dialog>
      
    <el-dialog
    top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="dialogVisinbile"
      :destroy-on-close="true"
      @close="close"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form :model="dtuForm" ref="subRef" label-width="120px" :rules="rules" v-loading="loading"
      element-loading-text="提交中请稍后..."
      >
      <el-form-item label="用途" prop="purpose" v-if="isCreate == true">
        <el-select
           v-model="dtuForm.purpose"  
                  clearable
                  filterable-
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in purpose"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="生产单名称" prop="productionName" v-if="isCreate == true">
        <el-select
           v-model="dtuForm.productionName"  
                  clearable
                  filterable-
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in purpose"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="生产单编号" prop="productionNumber" v-if="isCreate == true">
        <el-select
           v-model="dtuForm.productionNumber"  
                  clearable
                  filterable-
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in purpose"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="客户" prop="consumer" v-if="isCreate == true">
        <el-select
           v-model="dtuForm.consumer"  
                  clearable
                  filterable-
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in consumer"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="平台" prop="platform" v-if="isCreate == true">
        <el-select
           v-model="dtuForm.platform"  
                  clearable
                  filterable-
                  placeholder="请选择"
                  default-first-option
                >
                  <el-option
                    label="中移"
                    :value="0"
                  >
                  </el-option>
                  <el-option
                    label="阿里云"
                    :value="1"
                  >
                  </el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="生成数量" prop="number" v-if="isCreate == true">
          <el-input-number
            v-model="dtuForm.number"
            autocomplete="off"
            :min="1"
            :max="300"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="版本号" prop="number" v-if="isCreate == true">
          <el-input-number
            v-model="dtuForm.dtuVersion"
            autocomplete="off"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="联网方式" prop="number" v-if="isCreate == true">
        <el-select
           v-model="dtuForm.networkModeId"  
                  clearable
                  filterable-
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in networkList"
                    :key="item.networkModeId"
                    :label="item.networkMode"
                    :value="item.networkModeId"
                  >
                  </el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="网关Id" prop="dtuId" v-if="isCreate == false">
          <el-input
            :disabled="true"
            v-model="dtuForm.dtuId"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="联网方式" prop="networkModeId">
          <el-select
            v-model="dtuForm.networkModeId"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in networkModeArr"
              :key="item.networkModeId"
              :label="item.networkMode"
              :value="item.networkModeId"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item  label="硬件型号" prop="hardwareTypeId">
          <el-select
            v-model="dtuForm.hardwareTypeId"
            filterable
            placeholder="请选择"
            @change="hardChange"
          >
            <el-option
              v-for="item in hardwareArr"
              :key="item.hardwareTypeId"
              :label="item.typeName"
              :value="item.hardwareTypeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="固件类型" prop="firmwareTypeId">
          <el-select
            v-model="dtuForm.firmwareTypeId"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in firmwareArr"
              :key="item.firmwareTypeId"
              :label="item.firmwareTypeName"
              :value="item.firmwareTypeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button size="mini" type="primary" @click="submitForm" :disabled="loading">确 定</el-button>
        <el-button size="mini" @click="close">取 消</el-button>
       
      </div>
    </el-dialog>

    <el-dialog
    top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="detail"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form :model="dtuForm" ref="subRef" label-width="120px" :rules="rules">
        <el-form-item label="所属方案"  >
          <el-input
            readonly
            v-model="detailList.planName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属网关"  >
          <el-input
            readonly
            v-model="detailList.gatewayName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属项目"  >
          <el-input
            readonly
            v-model="detailList.projectName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
         <el-form-item label="所属项目组">
            <el-input v-model="detailList.projectGroupName" readonly></el-input>
          </el-form-item>
        <el-form-item label="绑定用户"  >
          <el-input
            readonly
            v-model="detailList.bindingUser"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="detail = false">确 定</el-button>
        <el-button size="mini" @click="detail = false">取 消</el-button>
        
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
import {
  OSS_URL,
  PLAN_IMG,
  gatewayState,
  bindingState,
} from "../util/constant";

let that;
export default {
  name: "CreateDtu",
  data() {
    return {
      purpose: [
        {label:'自用',value:0},
        {label:'道企',value:1},
      ],
      consumer: [
        {label:'自用',value:0},
        {label:'汇邦',value:1},
        {label:'百鸟',value:2}, 
      ],
      loading:false,
      FileName:'',
            uploadFileForm:{
        networkModeId:"",
         file:null,
      },
      uploadFile:false,
      constant: { OSS_URL, PLAN_IMG, gatewayState, bindingState },
      theme: { TABLE_HEADER_COLOR },
      dialogVisinbile: false,
      tableData: [],
      options: [{
          value: '1',
          label: '在线'
        }, {
          value: '2',
          label: '离线',
         
        }, {
          value: '3',
          label: '告警'
        }],
      getForm: {
        platform:1,
        size: 10,
        current: 1,
        total: 0,
        dtuSn: "",
        dtuId:"",
        hardwareTypeId: "",
        firmwareTypeId: "",
        networkModeId: "",
        dtuState:"",
      },
      dtuForm: {
        number: 1,
        gatewayId: "",
        dtuVersion:1,
        networkModeId: "",
        hardwareTypeId: "",
        firmwareTypeId: "",
        purpose: 0,
        productionName: 0,
        productionNumber: 0,
        consumer:0
      },
      formTitle: "",
      isCreate: true,
      hardwareArr: [],
      firmwareArr: [],
      firmwareArrList: [],
      networkModeArr: [],
      rules: {
        platform: [
          { required: true, message: "请选择平台", trigger: "blur" },
        ],
        purpose: [
          { required: true, message: "必选项不能为空", trigger: "blur" },
        ],
        productionName: [
          { required: true, message: "必选项不能为空", trigger: "blur" },
        ],
        productionNumber: [
          { required: true, message: "必选项不能为空", trigger: "blur" },
        ],
        consumer: [
          { required: true, message: "必选项不能为空", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入下发数量", trigger: "blur" },
        ],
        networkModeId: [
          { required: true, message: "请选择联网方式", trigger: "blur" },
        ],
        hardwareTypeId: [
          { required: true, message: "请选择硬件类型", trigger: "blur" },
        ],
        firmwareTypeId: [
          { required: true, message: "请选择固件类型", trigger: "blur" },
        ],
      },
      detail: false,
      detailList: {
        bindingUser: "",
        gatewayName: "",
        projectName: "",
        planName:"",
        
      },
      networkList:[],
    };
  },
  methods: {
    close() {
      that.dtuForm= {
        number: 1,
        gatewayId: "",
        dtuVersion:1,
        networkModeId: "",
        hardwareTypeId: "",
        firmwareTypeId: "",
        purpose: 0,
        productionName: 0,
        productionNumber: 0,
        consumer:0
      },
              that.dialogVisinbile = false;
              that.$refs["subRef"].resetFields();
    },
  file(){
      this.$refs.files.click();
    },
    uploadFileVis(){
      that.uploadFile = true;
      that.getModeList()
    },
    upload(e) {
      const file = e.target.files[0];   
      that.uploadFileForm.file = file;
      that.FileName=file.name
      document.getElementById("files").value="";
    },
    // 参数导入
    parameterImport() {
      const formData = new FormData()
      formData.append('file', that.uploadFileForm.file)
      formData.append('networkModeId',that.uploadFileForm.networkModeId)
      if (!that.uploadFileForm.file) {
        this.$message({
            type: "error",
            message: "请选择文件！"
          });
        return ;
      }
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      });
      that.$api.dtu.dtuImport(formData).then(res=>{
         loading.close();
        if (res.code==200) {
        
          this.$message({
            type: "success",
            message: res.msg,
          });
        that.$set(that.getForm, "current", 1);
        that.getDtuPage();
        that.uploadFile=false;
        }
        
      });
       
    },



    getModeList() {
      that.$api.gateway.getNetworkModeList('').then((response) => {
        if (response.code == 200) {
          that.networkList = response.data;
        }
      });
    },




    upgrade(dtuId) {
        that
        .$confirm("确定升级？", "提示", {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
         
          cancelButtonClass:"cancelBtn",
        })
        .then(() => {
      that.$api.dtu.upgrade(dtuId).then((response) => {
        if (response) {
          that.$message({
            type: "success",
            message: response.msg,
          })
        }
        
      });
      })
        .catch(() => {});
     
    },
    details(dtuSn) {
      that.$api.dtu.details(dtuSn).then((response) => {
        that.detail = true;
        // console.log(response);
        that.detailList = response.data;
      });
    },
    handleSizeChange(val) {
      that.$set(that.getForm, "size", val);
      that.$set(that.getForm, "current", 1);
      that.getDtuPage(that.getForm);
    },
    handleCurrentChange(val) {
      that.$set(that.getForm, "current", val);
      that.getDtuPage(that.getForm);
    },
    selectData() {
      that.$set(that.getForm, "current", 1);
      that.getDtuPage(that.getForm);
    },
    getDtuPage() {
      that.$api.dtu.getDtuPage(that.getForm).then((response) => {
        if (response.code == 200) {
          that.tableData = response.data.records;
          that.getForm.total = response.data.total;
        }
      });
    },
    hardwareList() {
      that.$api.dtu.hardwareList().then((response) => {
        if (response.code == 200) {
          that.hardwareArr = response.data;
        }
      });
    },
    hardChange(v) {
      that.$api.dtu.firmwareTypes(v).then((response) => {
        if (response.code == 200) {
          that.firmwareArr = response.data;
        }
      });
    },
    hardChangeList(v) {
      that.$api.dtu.firmwareTypes(v).then((response) => {
        if (response.code == 200) {
          that.firmwareArrList = response.data;
        }
      });
    },

    networkModeList() {
      that.$api.dtu.networkModeList().then((response) => {
        if (response.code == 200) {
          that.networkModeArr = response.data;
        }
      });
    },
    addDtu() {
     that.getModeList()
      that.dialogVisinbile = true;
      that.isCreate = true;
      that.formTitle = "生成序列号";
    },
    editDtu(obj) {
      that.dtuForm = obj;
      that.dialogVisinbile = true;
      that.isCreate = false;
      that.formTitle = "修改网关";
      that.hardChange(obj.hardwareTypeId);
    },
    delDtu(id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.dtu.delDtu(id).then((response) => {
            if (response.code == 200) {
              that.getDtuPage();
              that.$message({
                type: "success",
                message: "删除成功",
              });
            }
          });
        })
        .catch(() => {});
    },
    submitForm() {
      that.$refs["subRef"].validate((valid) => {
        if (valid) {
          that.loading=true
          let _api = that.isCreate
            ? that.$api.dtu.addDtu(that.dtuForm)
            : that.$api.dtu.editDtu(that.dtuForm);
          _api.then((response) => {
            that.loading=false
            if (response.code == 200) {
          that.close()
              that.getDtuPage();
              that.$message({
                type: "success",
                message: response.msg,
              });

              if (this.isCreate == true) {
                window.location.href = response.data;
              }

              that.isCreate = true;
            }
          });
        }
      });
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getDtuPage();
    that.hardwareList();
    that.networkModeList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-dtu-create {
  float: right;
  margin-top: 8px;
}
.iot-dtu-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-dtu >>> .iot-step-body {
  width: 100%;
  height: calc(100% - 40px);
  padding: 30px;
}

.iot-dtu >>> .el-dialog__body {
  width: 95%;
  height: 90%;
  overflow: hidden;
}

.dtu-tab {
  overflow-x: auto;
}
.iot-dtu >>> .el-form-item {
  vertical-align: middle;
}
</style>
